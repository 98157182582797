import React from 'react';

const LegalNotice = (props) => (
  <main>
    <section>
      <div className="relative py-16 bg-white overflow-hidden">
        <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
          <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
            <svg
              className="absolute top-12 left-full transform translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
            </svg>
            <svg
              className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
            </svg>
            <svg
              className="absolute bottom-12 left-full transform translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="d3eb07ae-5182-43e6-857d-35c643af9034"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
            </svg>
          </div>
        </div>
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="text-lg max-w-prose mx-auto">
            <h1>
            <span className="block text-base text-center font-semibold tracking-wide uppercase">
              Ce site est la propriété de TOBIM
            </span>
              <span className="mt-2 primary block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Éditeur
            </span>
            </h1>
            <p className="mt-8 text-xl text-gray-900 leading-8">
              TOBIM - S.A.S. au capital de 100 euros - 908 236 409 RCS Cayenne - Siège social :
              KELEX – route de la Madeleine – 258 avenue Justine Catayée – 97300 Cayenne –
              France
            </p>
          </div>
          <div className="mt-6 prose prose-indigo prose-lg text-gray-900 mx-auto">
            <h2>
              <span className="mt-2 primary block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Direction de la publication
            </span>
            </h2>
            <p>
              Manuel CLERIMA – Directeur général de TOBIM
            </p>
            <h2>
              <span className="mt-2 primary block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Graphisme et maintenance
            </span>
            </h2>
            <p>Katleen Sorhaindo</p>
            <h2>
              <span className="mt-2 primary block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Protection des données à caractère personnelles
            </span>
            </h2>
            <p>Aucune donnée nominative n’est collectée à votre insu.
              Aucune information nominative n’est cédée à un tiers.</p>
            <h2>
              <span className="mt-2 primary block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Propriété intellectuelle / droits des utilisateurs
            </span>
            </h2>
            <p>TOBIM est le titulaire exclusif de tous les droits de propriété intellectuelle portant tant sur la structure
              que sur le contenu du site www.to-bim.fr et ce pour le monde entier.
              <br/>La consultation et l’utilisation des données qu’il contient sont réservées à un usage personnel non commercial, ce qui exclut toute exploitation commerciale à un niveau local, national ou international. Notamment, aucun logo, texte, son, graphique ou image contenus dans le site ne doit être copié, reproduit, modifié, publié, émis, posté, transmis ou distribué par quelques moyens que ce soit sans l’autorisation préalable écrite de TOBIM.
              <br/>Le droit de reproduction est strictement limité aux cas prévus par l’article L. 122-5 du code de la propriété intellectuelle. Lorsqu’elle permise, la reproduction comporte obligatoirement :
              <br/>La mention de la source du document, en caractères très apparents et ainsi libellée : « Ce document provient du site internet de la société TOBIM – www.to-bim.fr - Tous droits de représentation et de reproduction sont réservés. »
              <br/>la mention du nom de l’auteur ;
              <br/>Le respect de l’intégrité de l’article (aucune altération, à l’exception des citations partielles).
            </p>
            <h2>
              <span className="mt-2 primary block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Marque et modèles
            </span>
            </h2>
            <p>Toutes les marques citées et les modèles de produits sont protégés par la loi et les Conventions nationales et internationales en vigueur.</p>
            <h2>
              <span className="mt-2 primary block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Liens Hypertextes
            </span>
            </h2>
            <p>Les pages de ce site Web pourront présenter des liens avec d’autres sites ou faire des renvois à d’autres sites. Ces liens vers ne sont fournis que pour la commodité de l’utilisateur de ce site Web.Ils sont activés sous la seule responsabilité de l’utilisateur. TOBIM ne saurait être tenu responsable d’un préjudice matériel, immatériel ou moral pour quelque cause que ce soit qui résulterait de l’activation des liens hypertextes disponibles sur le site.</p>
            <h2>
              <span className="mt-2 primary block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Responsabilités
            </span>
            </h2>
            <p>La connexion au site se fait sous l’entière responsabilité de l’utilisateur.
              La responsabilité de TOBIM ne pourra être recherchée pour tous dommages directs ou indirects, matériels ou immatériels qui résulteraient de la connexion au site, d’une interruption ou d’un dysfonctionnement de celui-ci quelle qu’en soit la cause. Les commentaires et opinions sont exprimés sur le présent site Internet sous la responsabilité de leurs auteurs respectifs.</p>
          </div>
        </div>
      </div>
    </section>
  </main>
);

export default LegalNotice;