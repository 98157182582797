import { Fragment } from 'react'
import CustomLink from "../../shared-component/config/CustomLink"
import { Link } from "react-router-dom";
import { Popover, Transition } from '@headlessui/react'
import {
  HomeIcon,
  UserIcon,
  MailIcon,
  MenuIcon,
  ShoppingCartIcon,
  XIcon,
} from '@heroicons/react/outline'
import "./Navbar.css"

const solutions = [
  {
    name: 'Accueil',
    description: 'Bienvenue chez ToBIM',
    href: '/',
    icon: HomeIcon,
  },
  {
    name: 'A Propos',
    description: 'Pour mieux comprendre qui nous sommes et ce que nous pouvons vous apporter.',
    href: 'about',
    icon: UserIcon,
  },
  { name: 'Contact', description: "Pour toutes questions n'hésitez pas à nous contacter", href: 'contact', icon: MailIcon },
  {
    name: 'Objets BIM',
    description: "Une librairie d'objets utile et intégrable à tout vos projets",
    href: 'objects-bim',
    icon: ShoppingCartIcon,
  },
]

export default function Navbar() {
  return (
    <Popover className="relative navbar">
      <div className="absolute inset-0 shadow z-30 pointer-events-none" aria-hidden="true" />
      <div className="relative z-20 ml-2 md:ml-8 flex items-center justify-between md:justify-start">
        <div>
          <Link to="/" className="flex">
            <span className="sr-only">TOBIM</span>
            <img
              className="h-8 w-auto sm:h-10"
              src="assets/img/logo/logo.jpeg"
              alt="TOBIM"
            />
          </Link>
        </div>
        <div className="max-w-7xl flex md:flex-1 justify-between items-center px-4 py-5 sm:px-6 sm:py-4 lg:px-8 md:justify-start md:space-x-10">
          
          <div className="-mr-2 -my-2 md:hidden">
            <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-700">
              <span className="sr-only">Open menu</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <div className="hidden md:flex-1 md:flex md:items-center md:justify-between">
              {solutions.map((item) => (
                <CustomLink
                  key={item.name}
                  to={item.href}
                  className="-m-3 flex items-center menu-item"
                >
                  <div className="mx-4 text-base font-medium">{item.name}</div>
                </CustomLink>
              ))}
          </div>
        </div>
      </div>
      
      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
        >
          {({close}) => (
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
            <div className="pt-5 pb-6 px-5 sm:pb-8">
              <div className="flex items-center justify-between">
                <div>
                  <img
                    className="h-8 w-auto"
                    src="assets/img/logo/logo.jpeg"
                    alt="TOBIM logo"
                  />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-700">
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-6 sm:mt-8">
                <nav>
                  <div className="grid gap-7 sm:grid-cols-2 sm:gap-y-8 sm:gap-x-4">
                    {solutions.map((item) => (
                      
                      <Link
                      key={item.name}
                      to={item.href}
                      className="-m-3 flex items-center p-3 rounded-lg hover:bg-gray-50"
                      onClick={() => close()}
                      >
                      <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md menu-icon text-white sm:h-12 sm:w-12">
                      <item.icon className="h-6 w-6" aria-hidden="true" />
                      </div>
                      <div className="ml-4 text-base font-medium text-gray-900">{item.name}</div>
                      </Link>
                    ))}
                  </div>
                </nav>
              </div>
            </div>
          </div>
          )}
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}