import React from 'react';
import "./About.css";

const About = (props) => (
  <main>
    <section>
      <div className="max-w-7xl mx-auto py-8 sm:py-12 px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h1 className="mt-12 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
            Qui sommes-nous ?
          </h1>
        </div>
      </div>
      <div className="flex flex-wrap justify-center">
        <p className="block xl:inline text-md w-3/4 text-center">ToBim est une entreprise spécialisée dans BIM, née du désir de son directeur de partager son
          savoir-faire et son appétence pour l’intégration de l’intelligence dans les processus de construction.</p>
        <div className="flex flex-col md:flex-row justify-start md:justify-center items-center md:items-end">
          <img className="photo rounded-xl" src="assets/img/profile/portrait.jpg" alt="Manuel CLERIMA"/>
          <div className="text md:w-2/4 mx-8 mb-8 md:ml-16">
            <p className="text-left text-md">Je suis Manuel CLERIMA, BIM Manager et projeteur Structure.<br/><br/>Dans mon parcours
              professionnel, j’ai exercé les métiers de projeteur méthode, projeteur structure, coordinateur structure BIM,
              directeur de synthèse et manager de projets BIM.<br/><br/>J’ai ainsi participé à des chantiers d’envergure comme
              entre autres ceux des tours Sister (La Défense), de la réhabilitation de l’hôpital Lariboisière, la
              planification des travaux d’aménagement de la Tour Eiffel, de la Gare St-Denis Pleyel…<br/><br/>Aujourd’hui,
              je me lance dans une nouvelle aventure en tant que directeur de la société TOBIM, prêt à relever de
              nouveaux défis et à vous faire bénéficier de l’expérience que j’ai acquise ces 12 dernières années.</p>
          </div>
        </div>
      </div>
    </section>
  </main>
);

export default About;