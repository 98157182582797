import {Route, Routes} from 'react-router-dom';
import Layout from "./layout/Layout"
import Home from "./app/Home"
import About from "./app/About"
import Contact from "./app/Contact"
import Bim from "./app/Bim"
import LegalNotice from "./app/LegalNotice"
import CGU from "./app/CGU"
import RGPD from "./app/RGPD"
import './App.css';

function App() {
  return (
    <Layout>
      <Routes>
        <Route index element={<Home/>}/>
        <Route path="about" element={<About/>}/>
        <Route path="contact" element={<Contact/>}/>
        <Route path="objects-bim" element={<Bim/>}/>
        <Route path="legal-notice" element={<LegalNotice/>}/>
        <Route path="cgu" element={<CGU/>}/>
        <Route path="rgpd" element={<RGPD/>}/>
      </Routes>
    </Layout>
  
  );
}

export default App;
