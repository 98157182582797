import axios from 'axios'

const API = axios.create({
  baseURL: `https://mykproject.fr/`,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': true
  },
})

export default API