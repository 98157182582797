import { Link } from "react-router-dom";
export default function Bim() {
  return (
    <main className="my-8 flex-grow flex flex-col justify-start items-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
      <div className="flex-shrink-0 flex justify-center">
        <a href="/" className="inline-flex">
          <span className="sr-only">Workflow</span>
          <img
            className="h-24 w-auto"
            src="assets/img/logo/logo.jpeg"
            alt="TOBIM"
          />
        </a>
      </div>
      <div className="py-4">
        <div className="text-center">
          <div>
            <h1 className="my-4 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">Page en construction.</h1>
            <img src="assets/img/bg/construction.png" alt="Page en construction" className="max-h-96"/>
          </div>
          <div className="mt-12">
            <Link to="/" className="text-base font-medium text-indigo-600 hover:text-indigo-500">
              Retour à l'accueil<span aria-hidden="true"> &rarr;</span>
            </Link>
          </div>
        </div>
      </div>
    </main>
  )
}