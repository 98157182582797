import React from 'react';
import {
  Link,
  useMatch,
  useResolvedPath,
} from "react-router-dom";
import type { LinkProps } from "react-router-dom";

const CustomLink = ({children, to, ...props}: LinkProps) => {
  let resolved = useResolvedPath(to);
  let match = useMatch({path: resolved.pathname, end: true});
  
  return (
    <div className={match && "bg-white p-4 rounded-lg"}>
      <Link
        to={to}
        style={{color: match && "rgb(21, 76, 90)"}}
        {...props}
      >
        {children}
      </Link>
    </div>
  );
};

export default CustomLink;