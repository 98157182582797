import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './Home.css'

const navigation = [
  { name: 'AMO (Assistance Maîtrise d\'Ouvrage)', href: '#', current: true },
  {
    name: 'AMOE (Assistance à la maîtrise d\'Œuvre)',
    href: '#',
    current: false,
  },
  { name: 'Particuliers', href: '#', current: false },
  // { name: "Formation BIM", href: "#", current: false },
  // { name: 'Aide à l\'auto-construction', href: '#', current: false },
]

const description = [
  {
    ref: 'AMO (Assistance Maîtrise d\'Ouvrage)',
    sub: [
      {
        title: 'AMO BIM',
        desc: [
          'Identification des besoins et des objectifs BIM',
          'Réalisation d\'une charte BIM',
          'Réalisation d\'un cahier des charges BIM',
          'Définition des missions du BIM Manager',
        ],
      },
      {
        title: 'AMO BIM-GEM',
        desc: [
          'Réalisation d’une convention BIM-GEM',
          'Transformation d’une maquette DOE en maquette GEM',
          'Mise en place d’une plateforme pour l’enrichissement de la maquette GEM',
        ],
      },
      {
        title: 'Scan to BIM',
        desc: [
          'Capture de l’existant et fourniture d’un modèle nuage 3D',
          'Modélisation de l’existant dans une maquette numérique',
          'Insertion des paramètres et données d’exploitation',
        ],
      },
    ],
    current: true,
  },
  {
    ref: 'AMOE (Assistance à la maîtrise d\'Œuvre)',
    sub: [
      {
        title: 'Management BIM',
        desc: [
          'Réalisation d’une convention BIM',
          'Visa des maquettes métiers',
          'Animation de réunions de coordination BIM',
          'Conception d’une maquette de référence',
        ],
      },
      {
        title: 'Coordination BIM',
        desc: [
          'Mise en place du process BIM métier',
          'Conception d’un gabarit reprenant les consignes de la convention BIM',
          'Participation aux réunions de coordination',
        ],
      },
      {
        title: 'Synthèse BIM 3D: Phase conception et exécution',
        desc: [
          'Détection et analyse des clashs',
          'Animation des réunions de synthèse BIM',
          'Fourniture des rapports de synthèse',
        ],
      },
      {
        title: 'Modélisation BIM',
        desc: [
          'Réalisation d’une maquette BIM Structure',
          'Réalisation de bibliothèque d’objet BIM',
          'Réalisation des plans structure (sous conditions)',
        ],
      },
      {
        title: 'Méthodes BIM',
        desc: [
          'Réalisation de phasage 4D',
          'Conception de PIC 3D',
          'Création d’un carnet de rotation 3D',
        ],
      },
    ],
    current: false,
  },
  {
    ref: 'Particuliers',
    sub: [
      {
        title: 'Aide à l’auto-construction',
        desc: [
          'Constitution des pièces de permis de construire',
          'Métrés quantitatifs et calcul des coûts',
          'Etudes de différents variants',
          'Suivi de chantier',
          'Analyse énergétique',
          'Analyse d’ensoleillement',
          'Analyse sismique',
          'Conception des plans d’exécution',
        ],
      },
    ],
    current: false,
  },
  // {
  //   ref: 'AMOE (Assistance à la maîtrise d\'Œuvre)',
  //   desc: ['Modélisation d\'une maquette numérique BIM', 'Création d\'un gabarit métier', 'Création d\'un fichier de paramètres partagés', 'Création d\'une bibliothèque d\'objets BIM', 'Rédaction d\'une convention BIM', 'Rédaction d\'un plan d\'exécution BIM', 'BIM Management de projets', 'Création de Scripts Dynamo'],
  //   current: false
  // },
  // { ref: 'Particuliers', desc: ['Direction de Synthèse 3D projet'], current: false },
  // { ref: 'Formation BIM', desc: ['FORMATION E-LEARNING A VENIR'], current: false },
  // {
  //   ref: 'Aide à l\'auto-construction',
  //   desc: ['Constitution de pièces de permis de construire', 'Calcul des coûts', 'Etude de différents variants', 'Conseils sur la construction', 'Suivi de chantier'],
  //   current: false
  // },
]

function classNames (...classes) {
  return classes.filter(Boolean).join(' ')
}

const Home = (props) => {
  const [currentItem, setCurrentItem] = useState('Maquette numérique')
  return (
    <main className="lg:relative">
      <section>
        <div className="mx-auto max-w-7xl w-full pt-16 pb-20 text-center lg:py-32 lg:text-left">
          <div className="px-4 lg:w-1/2 sm:px-8 xl:pr-16">
            <h1
              className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl">
              <span className="block xl:inline">Bienvenue chez</span>{' '}
              <span className="block primary xl:inline">TOBIM</span>
            </h1>
            <div>
              <h3 className="mt-8 md:mt-12 md:mb-4 text-3xl">
                <span className="primary">N</span>otre expertise
              </h3>
              <p className="max-w-md mx-auto text-lg text-gray-800 sm:text-xl md:max-w-3xl">
                TOBIM est une entreprise spécialisée dans le BIM et la maquette
                numérique. De l’avant-projet à l’exécution nous vous
                accompagnons dans vos projets et mettons à votre service nos
                compétences.
              </p>
            </div>
            
            <div className="mt-10 sm:flex sm:justify-center lg:justify-start">
              <div className="rounded-md shadow">
                <Link
                  to="contact"
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-primary md:py-4 md:text-lg md:px-10"
                >
                  Nous contacter
                </Link>
              </div>
              <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
                <Link
                  to="objects-bim"
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md primary bg-white hover:bg-gray-50 md:py-4 md:text-lg md:px-10"
                >
                  Nos objets
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
          <img
            className="absolute inset-0 w-full hero-img object-cover"
            src="assets/img/bg/bg-home.jpg"
            alt=""
          />
        </div>
      </section>
      <section className="lg:relative">
        <div className="mx-auto max-w-7xl w-full pt-16 pb-20 text-center lg:py-32 lg:text-left">
          <div className="md:my-8 px-4  sm:px-8 xl:pr-16">
            <h3 className="md:mt-5 text-3xl">
              <span className="primary">N</span>os services
            </h3>
            <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
              <div className="flex flex-col flex-wrap w-full justify-center">
                <nav className="space-y-1 w-full" aria-label="Sidebar">
                  <ul className="flex flex-col md:flex-row flex-wrap">
                    {navigation.map((item) => (
                      <li
                        key={item.name}
                        onClick={() => {
                          setCurrentItem(item.name)
                        }}
                        className={classNames(
                          currentItem === item.name
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700 hover:bg-gray-50 hover:text-gray-900',
                          'flex items-center px-3 py-2 text-base font-medium rounded-md cursor-pointer'
                        )}
                        aria-current={
                          currentItem === item.name ? 'page' : undefined
                        }
                      >
                        <span className="truncate">{item.name}</span>
                      </li>
                    ))}
                  </ul>
                </nav>
                <div className="text-left m-4">
                  {description.map(
                    (d) =>
                      currentItem === d.ref &&
                      d.sub.map((s) => (
                        <>
                          <h3 className="text-lg leading-6 font-medium text-gray-900 pt-8">
                            {s.title}
                          </h3>
                          <ul className="divide-y divide-gray-200">
                            {s.desc.map((list) => (
                              <li key={list} className="py-4 flex">
                                <div className="ml-3">
                                  <p className="text-md font-medium">{list}</p>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </>
                      ))
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}

export default Home
