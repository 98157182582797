import React from 'react';

const CGU = (props) => (
  <main>
    <section>
      <div className="relative py-16 bg-white overflow-hidden">
        <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
          <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
            <svg
              className="absolute top-12 left-full transform translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
            </svg>
            <svg
              className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
            </svg>
            <svg
              className="absolute bottom-12 left-full transform translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="d3eb07ae-5182-43e6-857d-35c643af9034"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
            </svg>
          </div>
        </div>
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="text-lg max-w-prose mx-auto">
            <h1>
            <span className="primary block text-3xl text-center font-semibold tracking-wide uppercase">
              Conditions Générales de Vente de TOBIM SAS:
            </span>
            </h1>
            <h2>
              <span className="block text-2xl text-center font-semibold tracking-wide uppercase">
              Objet et définitions
            </span>
            </h2>
            <p className="my-8 text-xl text-gray-900 leading-8">
              Les présentes Conditions Générales régissent les relations et les contrats (ci-après le ou les “Contrat(s)”) entre d’une part TOBIM société actions simplifié de droit français ayant son siège social au : Kelex route de la Madeleine 258 avenue justine catayée 97300 Cayenne, et d’autre part ses Clients (le ou les “Client(s) ”). Les présentes Conditions Générales prévaudront sur les conditions générales du Client, sauf accord écrit de TOBIM, valablement représenté par son directeur général. Offres Toute offre formulée par ou au nom de TOBIM est faite sous réserve et n’engage pas TOBIM, sauf si cela est expressément mentionné par écrit. Bien que les offres, propositions, bons de commande, listes de prix, brochures, catalogues, folders et autres informations fournis par ou au nom de TOBIM soient préparés avec le plus grand soin, ces documents ne sont liants que si et pour autant que cela ait expressément confirmé par écrit par TOBIM. Le Client peut effectuer une commande en acceptant une offre écrite émanant de TOBIM dans un délai de trente (30) jours calendrier après la date à laquelle l’offre a été envoyée. Eurosia peut en tout temps et unilatéralement limiter la durée de validité de ses offres et/ou les révoquer. Prix Sauf s’il en a été convenu autrement par écrit, les prix sont exprimés : • en Euro (EUR); Les prix sont seulement valables pour la quantité proposée. Toute contestation concernant une facture devra être envoyée à TOBIM par courrier recommandé dans un délai de quinze (15) jours calendrier à compter de l’émission de la facture contestée. Le Client est présumé avoir irrévocablement accepté le montant de la facture après cette échéance. L’introduction d’une contestation par le Client ne l’autorise
              pas à retenir le paiement de la partie non-contestée de la facture. Tous les frais bancaires, taxes, droits accises et autres frais dus en raison de la fourniture des Services seront supportés par le Client. En cas de paiement tardif, le Client sera redevable à TOBIM, sans mise en demeure préalable, d’une indemnité forfaitaire de 10% du montant restant dû, avec un minimum de 125 EUR et d’un intérêt de retard de 1% par mois à compter de la date à laquelle le montant aurait dû être payé. Tous les frais judiciaires ou extra-judiciaires exposés par TOBIM en ce qui concerne ce qui précède – en ce compris les frais d’avocat et de conseils juridiques – seront mis à charge du Client. Inexécution des obligations et résiliation du Contrat Si une Partie se rend coupable de manquements contractuels ou est en défaut d’exécuter ses obligations, la Partie non défaillante le notifiera à la Partie défaillante et la mettra en demeure de remédier à ces manquements ou inexécutions. Si la Partie défaillante ne répare pas ses manquements ou n’exécute pas ses obligations dans un délai de trente (30) jours calendrier, la Partie non-défaillante pourra mettre un terme au Contrat immédiatement en en informant par écrit la Partie défaillante. En toute hypothèse, TOBIM a le droit de suspendre immédiatement la fourniture des Services si le Client viole les termes du Contrat ou des présentes
              Conditions Générales.
            </p>
            <p className="my-8 text-xl text-gray-900 leading-8">En cas de faillite, d’insolvabilité ou de toute autre forme de concours de créanciers affectant l’une des Parties, l’autre Partie pourra mettre fin au Contrat, sans motif, immédiatement et sans être redevable de dommages et intérêts à la Partie faillie ou insolvable. </p>
            <h2>
              <span className="block text-2xl text-center font-semibold tracking-wide uppercase">
              Garantie et responsabilité
            </span>
            </h2>
            <p className="my-8 text-xl text-gray-900 leading-8">TOBIM mettra tout en œuvre pour fournir les Services tels que décrits dans la documentation relevante. Le Client ne peut en aucun cas tenir TOBIM pour responsable d’un dommage dans le cadre de l’exécution d’un Contrat sauf si ce dommage résulte d’une faute grave ou intentionnelle de TOBIM. Le montant total du dommage pour lequel TOBIM pourrait être tenu responsable est limité au montant total payé par le Client à TOBIM dans le cadre du Contrat. TOBIM ne pourra en aucune hypothèse être tenue pour responsable de dommages indirects subis par le Client ou par un quelconque de ses partenaires contractuels. Les Parties conviennent expressément qu’il appartiendra au Client de prendre toutes les mesures nécessaires pour protéger, sécuriser et sauvegarder son système informatique et pour empêcher toute perte ou dommage à ses données. Livraison et acceptation Sauf convention dérogatoire écrite, les délais de livraison sont communiqués à titre indicatif et ne sont pas liants.</p>
            <p className="my-8 text-xl text-gray-900 leading-8">Le Client vérifiera avec soin à la livraison si les Services fournis correspondent à ceux qui ont été commandés. Le Client sera présumé avoir accepté les Services à la livraison, sauf en cas de plainte contenant des griefs détaillés et précis, envoyée à TOBIM par courrier recommandé dans les quinze (15) jours calendrier de la livraison. </p>
            <h2>
              <span className="block text-2xl text-center font-semibold tracking-wide uppercase">
              Force Majeure
            </span>
            </h2>
            <p className="my-8 text-xl text-gray-900 leading-8">Aucune des Parties ne sera responsable vis-à-vis de l’autre Partie en cas de retard ou d’empêchement dans l’exécution de ses obligations, si le retard ou l’empêchement résulte d’une cause ou de conditions échappant au contrôle raisonnable de la Partie obligée de s’exécuter, notamment en cas de grève, de fait du prince, de calamité naturelle, de guerre ou d’émeute (“Force majeure”). Si la Force majeure empêche ou retarde l’exécution par l’une des Parties d’une obligation résultant d’un Contrat, la Partie se prévalant de la Force majeure en avisera l’autre Partie par écrit, dans les plus brefs délais, en donnant toutes les raisons du retard ou de l’empêchement. L’obligation de prester incombant à une Partie empêchée par un cas de Force majeure sera suspendue jusqu’à ce que l’événement cesse. Si une telle suspension causée par la Force majeure se poursuit pendant une période de soixante (60) jours ou plus, chaque Partie sera autorisée à résilier le contrat moyennant notification écrite.
            </p>
            <h2>
              <span className="block text-2xl text-center font-semibold tracking-wide uppercase">
              Confidentialité et propriété intellectuelle
            </span>
            </h2>
            <p className="my-8 text-xl text-gray-900 leading-8">Les Parties reconnaissent que toute information et documentation, notamment relative à tous les services, produits, solutions, échantillons et spécifications, données, know-how, concepts, technologies, références, businessplans ou plans marketing, listes de prix, matériaux techniques ou non, et tout ce qui peut dériver de ce qui précède (ci-après “l’Information protégée"), créée et/ou communiquée par une Partie, avant ou après la signature d’un Contrat, est strictement confidentielle et reste la propriété de la Partie divulgante. Les obligations de confidentialité concernant l’Information protégée ne s’appliquent pas à l’information (a) qui a été développée indépendamment par la Partie non-divulgante, sans accès à l’Information protégée, (b) qui est ou devient publique en-dehors de tout acte illicite de la Partie nondivulgante, (c) qui a déjà été portée à la connaissance de la Partie non-divulgante par de la documentation émanant d’une tierce partie et portant une date antérieure à la divulgation de l’Information protégée, (d) dont la publication par la Partie non-divulgante a été autorisée par écrit par un représentant légal de la Partie. Sauf si les Parties en ont convenu autrement par écrit, tous les droits intellectuels, artistiques ou autres de Eurosia, de ses sous-traitants et/ou de ses fournisseurs, sur les programmes informatiques, configurations de software ou de hardware, banques de donnée, architectures informatiques, logos, copyrights et marques resteront la propriété exclusive de TOBIM ou de ses sous-traitants ou fournisseurs. </p>
            <h2>
              <span className="block text-2xl text-center font-semibold tracking-wide uppercase">
              Divers
            </span>
            </h2>
            <p className="my-8 text-xl text-gray-900 leading-8">Le fait qu'une Partie tolère un manquement ou une inexécution de toute obligation qui incombe à l'autre Partie en vertu d’un Contrat ne pourra être ni considéré ni interprété comme une renonciation à un droit résultant du Contrat. Aucune renonciation ne sera valable sans un accord écrit et signé par les deux Parties. Si une disposition quelconque des présentes Conditions Générales est déclarée illégale, nulle ou, pour quelque raison que ce soit, inexécutable, elle sera considérée comme dissociée des autres dispositions et n'aura pas d'effet sur la validité ou le caractère exécutoire des autres clauses. </p>
            
            
            
          </div>
        </div>
      </div>
    </section>
  </main>
);

export default CGU;